import {ShowApp as LegacyShowApp} from '../components/legacy/ShowApp'
import {ListingLayout} from '../components/ListingLayout'
import {Header} from '../components/apps/Header'
import {Body} from '../components/apps/Body'
import {Sidebar} from '../components/apps/Sidebar'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import type {ShowAppPayload} from '../types'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'

export function ShowApp() {
  const redesignFeatureFlag = useFeatureFlag('marketplace_layout_redesign')

  return <>{redesignFeatureFlag ? <RedesignedShowApp /> : <LegacyShowApp />}</>
}

const RedesignedShowApp = () => {
  const {listing, planInfo, userCanEdit, screenshots, supportedLanguages} = useRoutePayload<ShowAppPayload>()

  return (
    <ListingLayout
      header={<Header app={listing} planInfo={planInfo} userCanEdit={userCanEdit} />}
      body={
        <Body app={listing} screenshots={screenshots} planInfo={planInfo} supportedLanguages={supportedLanguages} />
      }
      sidebar={<Sidebar app={listing} planInfo={planInfo} supportedLanguages={supportedLanguages} />}
    />
  )
}

try{ ShowApp.displayName ||= 'ShowApp' } catch {}
try{ RedesignedShowApp.displayName ||= 'RedesignedShowApp' } catch {}