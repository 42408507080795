'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
var _createForOfIteratorHelper2 = _interopRequireDefault(require("@babel/runtime/helpers/createForOfIteratorHelper"));
var _require = require('./loadQuery'),
  loadQuery = _require.loadQuery;
var useIsMountedRef = require('./useIsMountedRef');
var useRelayEnvironment = require('./useRelayEnvironment');
var _require2 = require('react'),
  useCallback = _require2.useCallback,
  useEffect = _require2.useEffect,
  useInsertionEffect = _require2.useInsertionEffect,
  useRef = _require2.useRef,
  useState = _require2.useState;
var _require3 = require('relay-runtime'),
  getRequest = _require3.getRequest;
var initialNullQueryReferenceState = {
  kind: 'NullQueryReference'
};
function requestIsLiveQuery(preloadableRequest) {
  if (preloadableRequest.kind === 'PreloadableConcreteRequest') {
    return preloadableRequest.params.metadata.live !== undefined;
  }
  var request = getRequest(preloadableRequest);
  return request.params.metadata.live !== undefined;
}
var CLEANUP_TIMEOUT = 1000 * 60 * 5;
function useQueryLoader_EXPERIMENTAL(preloadableRequest, initialQueryReference) {
  var initialQueryReferenceInternal = initialQueryReference !== null && initialQueryReference !== void 0 ? initialQueryReference : initialNullQueryReferenceState;
  var environment = useRelayEnvironment();
  var isMountedRef = useIsMountedRef();
  var undisposedQueryReferencesRef = useRef(null);
  if (undisposedQueryReferencesRef.current == null) {
    undisposedQueryReferencesRef.current = new Set([initialQueryReferenceInternal]);
  }
  var _useState = useState(function () {
      return initialQueryReferenceInternal;
    }),
    queryReference = _useState[0],
    setQueryReference = _useState[1];
  var _useState2 = useState(function () {
      return initialQueryReferenceInternal;
    }),
    previousInitialQueryReference = _useState2[0],
    setPreviousInitialQueryReference = _useState2[1];
  if (initialQueryReferenceInternal !== previousInitialQueryReference) {
    var _undisposedQueryRefer;
    (_undisposedQueryRefer = undisposedQueryReferencesRef.current) === null || _undisposedQueryRefer === void 0 ? void 0 : _undisposedQueryRefer.add(initialQueryReferenceInternal);
    setPreviousInitialQueryReference(initialQueryReferenceInternal);
    setQueryReference(initialQueryReferenceInternal);
  }
  var disposeQuery = useCallback(function () {
    if (isMountedRef.current) {
      var _undisposedQueryRefer2;
      (_undisposedQueryRefer2 = undisposedQueryReferencesRef.current) === null || _undisposedQueryRefer2 === void 0 ? void 0 : _undisposedQueryRefer2.add(initialNullQueryReferenceState);
      setQueryReference(initialNullQueryReferenceState);
    }
  }, [isMountedRef]);
  var queryLoaderCallback = useCallback(function (variables, options) {
    var _options$__environmen, _undisposedQueryRefer3;
    if (!isMountedRef.current) {
      return;
    }
    var mergedOptions = options != null && options.hasOwnProperty('__environment') ? {
      fetchPolicy: options.fetchPolicy,
      networkCacheConfig: options.networkCacheConfig,
      __nameForWarning: options.__nameForWarning
    } : options;
    var updatedQueryReference = loadQuery((_options$__environmen = options === null || options === void 0 ? void 0 : options.__environment) !== null && _options$__environmen !== void 0 ? _options$__environmen : environment, preloadableRequest, variables, mergedOptions);
    (_undisposedQueryRefer3 = undisposedQueryReferencesRef.current) === null || _undisposedQueryRefer3 === void 0 ? void 0 : _undisposedQueryRefer3.add(updatedQueryReference);
    setQueryReference(updatedQueryReference);
  }, [environment, preloadableRequest, setQueryReference, isMountedRef]);
  var disposeAllRemainingQueryReferences = useCallback(function disposeAllRemainingQueryReferences(preloadableRequest, currentQueryReference) {
    var _undisposedQueryRefer4;
    var undisposedQueryReferences = (_undisposedQueryRefer4 = undisposedQueryReferencesRef.current) !== null && _undisposedQueryRefer4 !== void 0 ? _undisposedQueryRefer4 : new Set();
    var _iterator = (0, _createForOfIteratorHelper2["default"])(undisposedQueryReferences),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var undisposedQueryReference = _step.value;
        if (undisposedQueryReference === currentQueryReference) {
          continue;
        }
        if (undisposedQueryReference.kind !== 'NullQueryReference') {
          if (requestIsLiveQuery(preloadableRequest)) {
            undisposedQueryReference.dispose && undisposedQueryReference.dispose();
          } else {
            undisposedQueryReference.releaseQuery && undisposedQueryReference.releaseQuery();
          }
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }, []);
  var cleanupTimerRef = useRef(null);
  useEffect(function () {
    disposeAllRemainingQueryReferences(preloadableRequest, queryReference);
    if (cleanupTimerRef.current != null) {
      clearTimeout(cleanupTimerRef.current);
      cleanupTimerRef.current = null;
    }
    return function () {
      cleanupTimerRef.current = setTimeout(function () {
        disposeAllRemainingQueryReferences(preloadableRequest, null);
      }, CLEANUP_TIMEOUT);
    };
  }, [preloadableRequest, queryReference]);
  useInsertionEffect(function () {
    return function () {
      cleanupTimerRef.current && clearTimeout(cleanupTimerRef.current);
      cleanupTimerRef.current = null;
      disposeAllRemainingQueryReferences(preloadableRequest, null);
    };
  }, [preloadableRequest]);
  return [queryReference.kind === 'NullQueryReference' ? null : queryReference, queryLoaderCallback, disposeQuery];
}
module.exports = useQueryLoader_EXPERIMENTAL;