import {StaticFilterProvider} from '@github-ui/filter/providers'
import {AppsIcon, PlayIcon, AiModelIcon, CopilotIcon} from '@primer/octicons-react'

export const ListingTypeFilterProvider = new StaticFilterProvider(
  {
    key: 'in',
    displayName: 'Listing Type',
    icon: AppsIcon,
    priority: 1,
    description: 'Filter by listing type',
    aliases: ['type'],
  },
  [
    {
      value: 'copilot',
      description: 'Copilot extensions only',
      priority: 1,
      icon: CopilotIcon,
      displayName: 'Copilot extensions',
      type: 'keyword',
    },
    {
      value: 'models',
      description: 'Models only',
      priority: 2,
      icon: AiModelIcon,
      displayName: 'Models',
      type: 'keyword',
    },
    {
      value: 'apps',
      description: 'Apps only',
      priority: 3,
      icon: AppsIcon,
      displayName: 'Apps',
      type: 'keyword',
    },
    {
      value: 'actions',
      description: 'Actions only',
      priority: 4,
      icon: PlayIcon,
      displayName: 'Actions',
      type: 'keyword',
    },
  ],
  {
    filterTypes: {
      exclusive: false,
      multiValue: false,
      multiKey: false,
      valueless: false,
    },
  },
)
