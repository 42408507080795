import type {CopilotMarkdownExtension} from '../../extension'
import styles from './streaming-cursor.module.css'

import {findAndReplace} from 'hast-util-find-and-replace'
import {h} from 'hastscript'

export const char = '▋'
const regex = new RegExp(char, 'g')

/**
 * Renders a blinking cursor after the markdown. Note that this doesn't work in React blocks since they replace
 * the entire HTML for the block. React blocks should handle their own streaming state by checking if they are
 * incomplete and if the message is streaming.
 */
export default function streamingCursorExtension(): CopilotMarkdownExtension {
  return {
    // Inserting the caret before rendering ensures that we keep it inside the current tag (ie, at the end of a header)
    preprocessMarkdown: md => `${md} ${char}`,
    marked: [
      {
        hooks: {
          preprocess: md => md,
          // eslint-disable-next-line github/unescaped-html-literal
          postprocess: html => html?.replaceAll(char, `<span class="${styles.streamingCursor}">${char}</span>`),
        },
      },
    ],
    sanitizer: {
      allowedClassNames: Object.values(styles),
    },
    transformHtml: tree => findAndReplace(tree, [regex, () => h('span', {class: styles.streamingCursor}, char)]),
  }
}
