import type {PlanInfo, Plan} from '../../../types'
import type {AppListing} from '@github-ui/marketplace-common'
import {Button} from '@primer/react'

type Props = {
  planInfo: PlanInfo
  plan: Plan
  listing: AppListing
}

export function InstallButton({planInfo, plan, listing}: Props) {
  let isDisabled
  let buttonText

  if (plan.directBilling) {
    isDisabled = !planInfo.isBuyable
    buttonText = `Set up with ${listing.name}`
  } else if (
    plan.isPaid &&
    plan.hasFreeTrial &&
    (planInfo.subscriptionItem.onFreeTrial || planInfo.anyAccountEligibleForFreeTrial)
  ) {
    isDisabled = !planInfo.isBuyable
    if (planInfo.isBuyable || planInfo.organizations.length > 0) {
      buttonText = `Try free for ${planInfo.freeTrialLength}`
    } else if (planInfo.viewerFreeTrialDaysLeft && planInfo.viewerFreeTrialDaysLeft > 0) {
      buttonText = `${planInfo.viewerFreeTrialDaysLeft} ${
        planInfo.viewerFreeTrialDaysLeft === 1 ? 'day' : 'days'
      } left of free trial`
    } else {
      buttonText = `Try free for ${planInfo.freeTrialLength}`
    }
  } else if (plan.isPaid) {
    isDisabled = !planInfo.isBuyable
    buttonText = 'Buy with GitHub'
  } else if (planInfo.isBuyable) {
    isDisabled = false
    buttonText = 'Install it for free'
  } else {
    isDisabled = true
    buttonText = 'Install it for free'
  }

  return (
    <Button
      variant="primary"
      size="large"
      disabled={isDisabled}
      type="submit"
      data-octo-click="marketplace-listing_order_click"
      data-octo-dimensions={`marketplace_listing_id:${listing.id}`}
    >
      {buttonText}
    </Button>
  )
}

try{ InstallButton.displayName ||= 'InstallButton' } catch {}