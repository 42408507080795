'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
var _createForOfIteratorHelper2 = _interopRequireDefault(require("@babel/runtime/helpers/createForOfIteratorHelper"));
var invariant = require('invariant');
function handleFieldErrors(environment, errorResponseFields) {
  var _iterator = (0, _createForOfIteratorHelper2["default"])(errorResponseFields),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var fieldError = _step.value;
      environment.relayFieldLogger(fieldError);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var _iterator2 = (0, _createForOfIteratorHelper2["default"])(errorResponseFields),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _fieldError = _step2.value;
      if (eventShouldThrow(_fieldError)) {
        switch (_fieldError.kind) {
          case 'relay_resolver.error':
            throw new Error("Relay: Resolver error at path '".concat(_fieldError.fieldPath, "' in '").concat(_fieldError.owner, "'."));
          case 'relay_field_payload.error':
            throw new Error("Relay: Unexpected response payload - this object includes an errors property in which you can access the underlying errors");
          case 'missing_expected_data.throw':
            throw new Error("Relay: Missing expected data at path '".concat(_fieldError.fieldPath, "' in '").concat(_fieldError.owner, "'."));
          case 'missing_required_field.throw':
            throw new Error("Relay: Missing @required value at path '".concat(_fieldError.fieldPath, "' in '").concat(_fieldError.owner, "'."));
          case 'missing_required_field.log':
          case 'missing_expected_data.log':
            break;
          default:
            _fieldError.kind;
            !false ? process.env.NODE_ENV !== "production" ? invariant(false, 'Relay: Unexpected event kind: %s', _fieldError.kind) : invariant(false) : void 0;
        }
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
}
function eventShouldThrow(event) {
  switch (event.kind) {
    case 'relay_resolver.error':
    case 'relay_field_payload.error':
      return event.shouldThrow && !event.handled;
    case 'missing_expected_data.throw':
    case 'missing_required_field.throw':
      return !event.handled;
    case 'missing_required_field.log':
    case 'missing_expected_data.log':
      return false;
    default:
      event.kind;
      throw new Error('Relay: Unexpected event kind');
  }
}
function handlePotentialSnapshotErrors(environment, errorResponseFields) {
  if (errorResponseFields != null) {
    handleFieldErrors(environment, errorResponseFields);
  }
}
module.exports = {
  handlePotentialSnapshotErrors: handlePotentialSnapshotErrors,
  eventShouldThrow: eventShouldThrow
};