import {useCallback, useEffect, useState} from 'react'
import {PlaygroundAPIMessageAuthorValues} from '../../../types'
import type {AzureModelClient} from '../../../utils/azure-model-client'
import {Panel} from '../../../utils/playground-manager'
import {defaultResponseFormat} from '../../../utils/model-state'
import type {Model} from '@github-ui/marketplace-common'

const improvementPrompt = `You are an AI prompt optimization specialist operating in an AI Model playground context. Your role is to analyze and improve system prompts while adhering to the following guidelines:

    Evaluate the given prompt based on:
    - Clarity and specificity of instructions
    - Alignment with intended goals
    - Potential for consistent model responses
    - Technical feasibility within model constraints
    - Absence of ambiguous or conflicting directions

    Provide improvements that:
    - Enhance precision and clarity
    - Maintain compatibility with AI Model playground parameters
    - Optimize for both effectiveness and efficiency
    - Remove redundancies and ambiguities
    - Include necessary context and constraints

    Focus solely on prompt improvement without engaging in task execution or additional commentary. Ensure all improvements maintain technical feasibility within standard AI Model playground limitations. Do not add surounding quotes to the suggested prompt.

    Please respond with the improved system prompt only, formatted clearly and ready for implementation.`

export function useImprovePrompt(
  systemPrompt: string,
  promptSuggestionText: string,
  playgroundManagerClient: AzureModelClient,
  improvedSysPromptModel: Model,
) {
  const [generatedPrompt, setGeneratedPrompt] = useState(systemPrompt)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const generatePrompt = useCallback(async () => {
    try {
      const instruction = `Improve this prompt: \n${systemPrompt} ${
        promptSuggestionText ? `with the following instruction ${promptSuggestionText}` : ''
      }`
      const user = PlaygroundAPIMessageAuthorValues[0]

      const messages = [
        {
          timestamp: new Date(),
          role: user,
          message: instruction,
        },
      ]
      let response = ''
      for await (const res of playgroundManagerClient.sendMessage(
        Panel.Main,
        improvedSysPromptModel,
        messages,
        {},
        improvementPrompt,
        defaultResponseFormat,
      )) {
        response = res.message.message as string
      }
      setGeneratedPrompt(response)
    } catch {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }, [improvedSysPromptModel, playgroundManagerClient, promptSuggestionText, systemPrompt])

  useEffect(() => {
    generatePrompt()
  }, [generatePrompt])

  return {generatedPrompt, isLoading, error}
}
