import {useCallback, useMemo, useState} from 'react'
import {CheckIcon} from '@primer/octicons-react'
import {InstallSection} from './InstallSection'
import {TermsOfService} from './TermsOfService'
import {Text, Radio, Label, Stack} from '@primer/react'
import styles from '../../../marketplace.module.css'
import type {PlanInfo} from '../../../types'
import type {AppListing} from '@github-ui/marketplace-common'
import {Octicon} from '@primer/react/deprecated'
import ListingBodySection from '../../ListingBodySection'

type Props = {
  planInfo: PlanInfo
  listing: AppListing
}

export function Plans({planInfo, listing}: Props) {
  const [selectedPlanId, setSelectedPlanId] = useState(planInfo.selectedPlanId)

  const onPlanChange = useCallback((planId: string) => {
    setSelectedPlanId(planId)
  }, [])

  const selectedPlan = useMemo(() => {
    return planInfo.plans.find(plan => plan.id === selectedPlanId)
  }, [planInfo.plans, selectedPlanId])

  return (
    <ListingBodySection title="Plans and pricing" data-testid="pricing" id="pricing-and-setup">
      <div
        className={`${styles['marketplace-content-container']} ${styles['marketplace-content-container--less-padding']}`}
      >
        <Stack gap="normal" className="border-bottom color-border-muted">
          {planInfo.plans.map((plan, idx) => (
            <Stack
              key={plan.id}
              gap="condensed"
              direction="horizontal"
              className={idx === planInfo.plans.length - 1 ? 'pb-3' : ''}
            >
              <Radio
                aria-describedby="price-yearly"
                name="plan"
                value={plan.id}
                id={plan.id}
                checked={selectedPlanId === plan.id}
                onChange={() => onPlanChange(plan.id)}
              />
              <Stack gap="condensed" direction="vertical" className="width-full">
                <Stack gap="condensed" direction="horizontal">
                  <Stack
                    gap={{narrow: 'none', regular: 'condensed', wide: 'condensed'}}
                    direction={{narrow: 'vertical', regular: 'horizontal', wide: 'horizontal'}}
                    justify="space-between"
                    className="width-full"
                  >
                    <Stack gap="none" direction="vertical" className="width-full">
                      <label htmlFor={plan.id} className="text-bold">
                        {plan.name}{' '}
                        {plan.isPaid && plan.hasFreeTrial && <Label className="ml-1">Free trial available</Label>}
                      </label>
                      <Text size="small" className="color-fg-muted mt-1">
                        {plan.description}
                      </Text>
                    </Stack>
                    <span className="color-fg-muted flex-shrink-0" data-testid="plan-price-info">
                      {plan.isPaid && (
                        <>
                          <span>{plan.price}</span>
                          {plan.perUnit && <span> {`/ ${plan.unitName}`}</span>}
                          <span>{planInfo.isUserBilledMonthly ? ' / month' : ' / year'}</span>
                        </>
                      )}
                      {!plan.isPaid && !plan.directBilling && <span>$0</span>}
                    </span>
                  </Stack>
                </Stack>
                {selectedPlan &&
                  selectedPlan.id === plan.id &&
                  (plan.forOrganizationsOnly || plan.forUsersOnly || plan.bullets.length > 0) && (
                    <Stack gap="condensed">
                      {plan.forOrganizationsOnly && <div>For organizations only</div>}
                      {plan.forUsersOnly && <div>For users only</div>}

                      {plan.bullets.length > 0 && (
                        <ul className="list-style-none d-flex flex-column gap-1">
                          {plan.bullets.map(bullet => (
                            <li key={bullet} className="d-flex gap-2">
                              <Octicon
                                icon={CheckIcon}
                                className="color-fg-success"
                                sx={{transform: 'translateY(2px)'}}
                              />
                              <Text as="span" size="small">
                                {bullet}
                              </Text>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          ))}
        </Stack>
        <InstallSection planInfo={planInfo} listing={listing} selectedPlan={selectedPlan} />
      </div>
      <TermsOfService planInfo={planInfo} plan={selectedPlan} listing={listing} />
    </ListingBodySection>
  )
}

try{ Plans.displayName ||= 'Plans' } catch {}