import {PlaygroundChatMessageHeader} from '../../playground/components/PlaygroundChatMessageHeader'
import {useUser} from '@github-ui/use-user'
import {InlineAutocomplete} from '@github-ui/inline-autocomplete'
import type {ShowSuggestionsEvent, Suggestion} from '@github-ui/inline-autocomplete/types'
import {useFeatureFlags} from '@github-ui/react-core/use-feature-flag'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {StackIcon, TrashIcon} from '@primer/octicons-react'
import {Button, FormControl, IconButton, PageLayout, Spinner, Textarea} from '@primer/react'
import {Blankslate} from '@primer/react/experimental'
import {useCallback, useEffect, useRef, useState} from 'react'
import {GiveFeedback} from '../../../components/GiveFeedback'
import type {EvalsRow, IndexModelsPayload, ModelInputChangeParams, ShowModelPayload} from '../../../types'
import type {AzureModelClient} from '../../../utils/azure-model-client'
import {validateAndFilterParameters} from '../../../utils/model-state'
import {Panel} from '../../../utils/playground-manager'
import {getPromptEvalsLocalStorage} from '../../../utils/prompt-evals-local-storage'
import {ModelSystemPrompt} from '../../playground/components/ModelSystemPrompt'
import {referencedVariables, replaceVars} from '../evals-sdk/variables'
import {useExtractedPrompt} from '../hooks/use-extracted-prompt'
import {VariableInput} from '../variables'
import {Header} from './Header'
import styles from './Prompt.module.css'
import {VariablesDialog} from './VariablesDialog'
import {ViewSwitcher} from './ViewSwitcher'
import {usePromptEvalsState} from '../contexts/PromptEvalsStateContext'
import {usePromptEvalsManager} from '../prompt-evals-manager'
import ParameterSettingsMenu from './ParameterSettingsMenu'
import {PlaygroundChatMessage} from '../../playground/components/PlaygroundChatMessage'

export type PromptProps = {
  modelClient: AzureModelClient
}

export function Prompt({modelClient}: PromptProps) {
  const {prompt, systemPrompt, variables, evals, model} = usePromptEvalsState()
  const {gettingStarted, modelInputSchema, catalogData, messages, isLoading} = model
  const lastIndex = messages.length - 1
  const manager = usePromptEvalsManager()
  const localStorage = getPromptEvalsLocalStorage()
  const featureFlags = useFeatureFlags()

  const {currentUser} = useUser()

  const modelStateWithParameters = localStorage?.parameters ? {...model, parameters: localStorage.parameters} : model

  const {promptExtractionModel} = useRoutePayload<ShowModelPayload>()

  const [variableSuggestions, setVariableSuggestions] = useState<Suggestion[]>([])
  const [variableKeys, setVariableKeys] = useState<string[]>(() => (prompt ? referencedVariables(prompt) : []))

  const messagesContainerRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    messagesContainerRef.current?.scrollIntoView(false)
  }, [])

  useEffect(() => {
    requestAnimationFrame(scrollToBottom)
  }, [messages, scrollToBottom])

  const onShowSuggestions = useCallback((_event: ShowSuggestionsEvent) => {
    // TODO: Get this from evals state, or separate Variables UI
    setVariableSuggestions([`{{${VariableInput}}}`])
  }, [])
  const onHideSuggestions = useCallback(() => {
    setVariableSuggestions([])
  }, [])

  const handlePromptInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const promptInput = event.target.value
      manager.setPromptInput(promptInput)

      setVariableKeys(referencedVariables(promptInput))
    },
    [manager],
  )

  const handleSystemPromptChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      manager.setSystemPrompt(event.target.value)
    },
    [manager],
  )

  const updateSystemPrompt = useCallback(
    (newPrompt: string) => {
      manager.setSystemPrompt(newPrompt)
    },
    [manager],
  )

  const handleModelParamsChange = ({key, value, validate}: ModelInputChangeParams) => {
    const unvalidatedParams = {
      ...modelStateWithParameters.parameters,
      [key]: value,
    }

    const params = validate
      ? validateAndFilterParameters(modelInputSchema?.parameters || [], unvalidatedParams)
      : unvalidatedParams

    manager.setParameters(params)
    manager.setParametersHasChanges(true)
  }

  const handleRun = useCallback(
    (vars: Record<string, string>) => {
      const usedVariables = referencedVariables(prompt)
      const variablesWithoutValue = usedVariables.filter(v => !vars[v])
      if (variablesWithoutValue.length > 0) {
        // One of the referenced variables doesn't have a value, ask the user for a value first
        setRequiredVariables(new Set(variablesWithoutValue))
        setShowRunVariablesDialog(true)
        return
      }

      // If this is a new variables combination, add as a data row
      if (!evals.rows.find(d => Object.keys(vars).every(v => d[v] === vars[v]))) {
        manager.evalsAddRow({
          id: evals.rows.length + 1,
          ...vars,
        } as EvalsRow)
      }

      const expandedPrompt = replaceVars(prompt, vars)
      manager.sendMessage(model, modelClient, systemPrompt, expandedPrompt)
    },
    [manager, model, modelClient, systemPrompt, prompt, evals.rows],
  )

  const handleStop = useCallback(() => {
    modelClient.stopStreamingMessages(Panel.Main)
  }, [modelClient])

  const {canExtractPrompt} = useRoutePayload<IndexModelsPayload>()
  const handleExtractedPrompt = useCallback(() => {
    manager.setPromptInput(prompt)
    manager.sendMessage(model, modelClient, systemPrompt, prompt)
  }, [manager, modelClient, model, systemPrompt, prompt])
  const {extractingPrompt} = useExtractedPrompt(
    promptExtractionModel || model.catalogData,
    modelClient,
    handleExtractedPrompt,
    !!canExtractPrompt,
  )

  const [showEditVariablesDialog, setShowVariablesDialog] = useState(false)
  const handleCloseEditVariablesDialog = useCallback(
    (v?: Record<string, string>) => {
      if (v) {
        manager.setVariables(v)
      }
      setShowVariablesDialog(false)
    },
    [manager],
  )

  const [showRunVariablesDialog, setShowRunVariablesDialog] = useState(false)
  const handleCloseRunVariablesDialog = useCallback(
    (v?: Record<string, string>) => {
      setShowRunVariablesDialog(false)

      if (v) {
        // Persist variables
        manager.setVariables(v)

        // Reset required variables
        setRequiredVariables(undefined)

        handleRun(v)
      }
    },
    [manager, handleRun],
  )

  const [requiredVariables, setRequiredVariables] = useState<Set<string> | undefined>(undefined)

  const blankSlate = (
    <div className={styles.blankSlate}>
      <Blankslate>
        <Blankslate.Visual>
          {extractingPrompt ? <Spinner size="medium" /> : <StackIcon size="medium" />}
        </Blankslate.Visual>
        <Blankslate.Heading>Iterate on your prompt</Blankslate.Heading>
        <Blankslate.Description>
          {extractingPrompt ? (
            'Extracting the prompt from your code...'
          ) : (
            <>
              Use the prompt editor to run a single prompt repeatedly, refining it and adjusting{' '}
              <code>{'{{variables}}'}</code> to achieve the perfect response.
            </>
          )}
        </Blankslate.Description>
      </Blankslate>
    </div>
  )

  return (
    <div className={styles.promptContainer}>
      {showEditVariablesDialog && (
        <VariablesDialog
          primaryTitle="Save"
          variables={variables}
          availableVariables={variableKeys}
          onClose={handleCloseEditVariablesDialog}
        />
      )}

      {showRunVariablesDialog && (
        <VariablesDialog
          primaryTitle="Run"
          variables={variables}
          availableVariables={variableKeys}
          requiredVariables={requiredVariables}
          onClose={handleCloseRunVariablesDialog}
        />
      )}

      <div className={styles.mobileHeader}>
        <GiveFeedback mobile />
      </div>
      <div className={styles.promptWrapper}>
        <div className={styles.responsiveWrapper}>
          <div className={styles.responsiveContainer}>
            <Header
              run={() => handleRun(variables)}
              stop={handleStop}
              running={isLoading}
              canRun={!!prompt}
              model={catalogData}
              modelInputSchema={modelInputSchema}
              gettingStarted={gettingStarted}
            />
            <div className="border overflow-hidden rounded-2 flex-grow-1 height-full">
              <div className="d-flex position-sticky top-0 p-2 border-bottom bgColor-muted flex-items-center">
                {featureFlags.github_models_prompt_evals && (
                  <>
                    <ViewSwitcher model={catalogData} />
                    <div
                      style={{width: '1px', height: '20px', margin: '0 8px', background: 'var(--borderColor-default'}}
                    />
                  </>
                )}
                <Button
                  size="small"
                  className="mr-2"
                  onClick={() => setShowVariablesDialog(true)}
                  disabled={variableKeys.length === 0}
                >
                  Edit variables
                </Button>
                <ParameterSettingsMenu model={model} handleModelParamsChange={handleModelParamsChange} />
                <div className="flex-1" />
                <IconButton
                  icon={TrashIcon}
                  size="small"
                  aria-label="Clear prompts and variables"
                  className="ml-2"
                  disabled={!systemPrompt && !prompt}
                  onClick={() => manager.evalsClearUserSystemPromptAndVariables()}
                />
              </div>
              <PageLayout padding="none" containerWidth="full" columnGap="none" className={styles.promptLayout}>
                <PageLayout.Pane resizable position="start" divider="none" padding="none">
                  <form className="p-3">
                    <div className="mb-3">
                      <ModelSystemPrompt
                        systemPrompt={systemPrompt ?? ''}
                        handleSystemPromptChange={handleSystemPromptChange}
                        updateSystemPrompt={updateSystemPrompt}
                        onSinglePlaygroundView
                        label="System"
                      />
                    </div>
                    <FormControl className="mb-3">
                      <FormControl.Label>User</FormControl.Label>
                      <InlineAutocomplete
                        fullWidth
                        tabInsertsSuggestions
                        triggers={[
                          {
                            triggerChar: '{{',
                            keepTriggerCharOnCommit: false,
                          },
                        ]}
                        suggestions={variableSuggestions}
                        onShowSuggestions={onShowSuggestions}
                        onHideSuggestions={onHideSuggestions}
                      >
                        <Textarea
                          value={prompt}
                          resize="vertical"
                          onChange={handlePromptInput}
                          placeholder="Enter the task or question for the model. Example: 'Write me a song about GitHub.' Use {{variable}} for placeholders."
                          block
                        />
                      </InlineAutocomplete>
                    </FormControl>
                  </form>
                </PageLayout.Pane>
                <PageLayout.Content as="div" className={styles.promptBody}>
                  {messages?.length > 0 ? (
                    <div className="p-3 overflow-auto height-full" ref={messagesContainerRef}>
                      {messages.map((message, index) => (
                        // eslint-disable-next-line @eslint-react/no-array-index-key
                        <div key={`${message.role}-${index}`}>
                          <PlaygroundChatMessageHeader
                            message={message}
                            model={model.catalogData}
                            currentUser={currentUser}
                            isLoading={index === lastIndex && isLoading}
                          />
                          <PlaygroundChatMessage
                            model={model}
                            message={message}
                            index={index}
                            isLoading={index === lastIndex && isLoading}
                            isError={message.role === 'error'}
                            lastIndex={index === lastIndex}
                            handleClearHistory={() => {}}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    blankSlate
                  )}
                </PageLayout.Content>
              </PageLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

try{ Prompt.displayName ||= 'Prompt' } catch {}