"use strict";

var _asyncToGenerator = require("@babel/runtime/helpers/asyncToGenerator").default;
var Observable = require('../network/RelayObservable');
var _require = require('../query/GraphQLTag'),
  getFragment = _require.getFragment;
var getPendingOperationsForFragment = require('../util/getPendingOperationsForFragment');
var _require2 = require('../util/handlePotentialSnapshotErrors'),
  handlePotentialSnapshotErrors = _require2.handlePotentialSnapshotErrors;
var _require3 = require('./RelayModernSelector'),
  getSelector = _require3.getSelector;
var invariant = require('invariant');
function waitForFragmentData(_x, _x2, _x3) {
  return _waitForFragmentData.apply(this, arguments);
}
function _waitForFragmentData() {
  _waitForFragmentData = _asyncToGenerator(function* (environment, fragment, fragmentRef) {
    var subscription;
    try {
      var _subscription;
      var data = yield new Promise(function (resolve, reject) {
        subscription = observeFragment(environment, fragment, fragmentRef).subscribe({
          next: function next(val) {
            if (val.state === 'ok') {
              resolve(val.value);
            } else if (val.state === 'error') {
              reject(val.error);
            }
          }
        });
      });
      (_subscription = subscription) === null || _subscription === void 0 ? void 0 : _subscription.unsubscribe();
      return data;
    } catch (e) {
      var _subscription2;
      (_subscription2 = subscription) === null || _subscription2 === void 0 ? void 0 : _subscription2.unsubscribe();
      throw e;
    }
  });
  return _waitForFragmentData.apply(this, arguments);
}
function observeFragment(environment, fragment, fragmentRef) {
  var _fragmentNode$metadat;
  var fragmentNode = getFragment(fragment);
  var fragmentSelector = getSelector(fragmentNode, fragmentRef);
  !(((_fragmentNode$metadat = fragmentNode.metadata) === null || _fragmentNode$metadat === void 0 ? void 0 : _fragmentNode$metadat.hasClientEdges) == null) ? process.env.NODE_ENV !== "production" ? invariant(false, "Client edges aren't supported yet.") : invariant(false) : void 0;
  !(fragmentSelector != null) ? process.env.NODE_ENV !== "production" ? invariant(false, 'Expected a selector, got null.') : invariant(false) : void 0;
  switch (fragmentSelector.kind) {
    case 'SingularReaderSelector':
      return observeSelector(environment, fragment, fragmentSelector);
    case 'PluralReaderSelector':
      {
        !false ? process.env.NODE_ENV !== "production" ? invariant(false, 'Plural fragments are not supported') : invariant(false) : void 0;
      }
  }
  !false ? process.env.NODE_ENV !== "production" ? invariant(false, 'Unsupported fragment selector kind') : invariant(false) : void 0;
}
function observeSelector(environment, fragmentNode, fragmentSelector) {
  var snapshot = environment.lookup(fragmentSelector);
  return Observable.create(function (sink) {
    sink.next(snapshotToFragmentState(environment, fragmentNode, fragmentSelector.owner, snapshot));
    var subscription = environment.subscribe(snapshot, function (nextSnapshot) {
      sink.next(snapshotToFragmentState(environment, fragmentNode, fragmentSelector.owner, nextSnapshot));
    });
    return function () {
      return subscription.dispose();
    };
  });
}
function snapshotToFragmentState(environment, fragmentNode, owner, snapshot) {
  var missingLiveResolverFields = snapshot.missingLiveResolverFields != null && snapshot.missingLiveResolverFields.length > 0;
  var missingClientEdges = snapshot.missingClientEdges != null && snapshot.missingClientEdges.length > 0;
  if (missingLiveResolverFields || missingClientEdges) {
    return {
      state: 'loading'
    };
  }
  if (snapshot.isMissingData) {
    var pendingOperations = getPendingOperationsForFragment(environment, fragmentNode, owner);
    if (pendingOperations != null) {
      return {
        state: 'loading'
      };
    }
  }
  try {
    handlePotentialSnapshotErrors(environment, snapshot.errorResponseFields);
  } catch (error) {
    return {
      error: error,
      state: 'error'
    };
  }
  !(snapshot.data != null) ? process.env.NODE_ENV !== "production" ? invariant(false, 'Expected data to be non-null.') : invariant(false) : void 0;
  return {
    state: 'ok',
    value: snapshot.data
  };
}
module.exports = {
  observeFragment: observeFragment,
  waitForFragmentData: waitForFragmentData
};