'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
var _useFragmentInternal_CURRENT = _interopRequireDefault(require("./useFragmentInternal_CURRENT"));
var _useFragmentInternal_EXPERIMENTAL = _interopRequireDefault(require("./useFragmentInternal_EXPERIMENTAL"));
var _relayRuntime = require("relay-runtime");
function useFragmentInternal(fragmentNode, fragmentRef, hookDisplayName, queryOptions) {
  if (_relayRuntime.RelayFeatureFlags.ENABLE_ACTIVITY_COMPATIBILITY) {
    return (0, _useFragmentInternal_EXPERIMENTAL["default"])(fragmentNode, fragmentRef, hookDisplayName, queryOptions);
  }
  return (0, _useFragmentInternal_CURRENT["default"])(fragmentNode, fragmentRef, hookDisplayName, queryOptions);
}
module.exports = useFragmentInternal;