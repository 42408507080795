'use strict';

var deepFreeze = require('../util/deepFreeze');
var getRequestIdentifier = require('../util/getRequestIdentifier');
var RelayFeatureFlags = require('../util/RelayFeatureFlags');
var _require = require('../util/stableCopy'),
  hasCycle = _require.hasCycle;
var _require2 = require('./RelayConcreteVariables'),
  getOperationVariables = _require2.getOperationVariables;
var _require3 = require('./RelayModernSelector'),
  createNormalizationSelector = _require3.createNormalizationSelector,
  createReaderSelector = _require3.createReaderSelector;
var _require4 = require('./RelayStoreUtils'),
  ROOT_ID = _require4.ROOT_ID;
var invariant = require('invariant');
function createOperationDescriptor(request, variables, cacheConfig) {
  var dataID = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ROOT_ID;
  var operation = request.operation;
  var operationVariables = getOperationVariables(operation, request.params.providedVariables, variables);
  if (RelayFeatureFlags.ENABLE_CYLE_DETECTION_IN_VARIABLES) {
    !!hasCycle(operationVariables) ? process.env.NODE_ENV !== "production" ? invariant(false, 'Cycle detected in variables passed to operation `%s`.', request.operation.name) : invariant(false) : void 0;
  }
  var requestDescriptor = createRequestDescriptor(request, operationVariables, cacheConfig);
  var operationDescriptor = {
    fragment: createReaderSelector(request.fragment, dataID, operationVariables, requestDescriptor),
    request: requestDescriptor,
    root: createNormalizationSelector(operation, dataID, operationVariables)
  };
  if (process.env.NODE_ENV !== "production") {
    Object.freeze(operationDescriptor.fragment);
    Object.freeze(operationDescriptor.root);
    Object.freeze(operationDescriptor);
  }
  return operationDescriptor;
}
function createRequestDescriptor(request, variables, cacheConfig) {
  var requestDescriptor = {
    identifier: getRequestIdentifier(request.params, variables),
    node: request,
    variables: variables,
    cacheConfig: cacheConfig
  };
  if (process.env.NODE_ENV !== "production") {
    deepFreeze(variables);
    Object.freeze(request);
    Object.freeze(requestDescriptor);
  }
  return requestDescriptor;
}
module.exports = {
  createOperationDescriptor: createOperationDescriptor,
  createRequestDescriptor: createRequestDescriptor
};