import {Stack} from '@primer/react'
import type {AppListing} from '@github-ui/marketplace-common'
import {CommentDiscussionIcon, ReportIcon, BookIcon, LawIcon, PulseIcon} from '@primer/octicons-react'
import {useMemo} from 'react'
import SidebarHeading from '@github-ui/marketplace-common/SidebarHeading'
import {reportAbusePath} from '@github-ui/paths'
import {ssrSafeWindow} from '@github-ui/ssr-utils'
import {ResourceList} from '../../sidebar-shared/ResourceList'

type ResourcesProps = {
  app: AppListing
}
function getReportAbusePath(app: AppListing) {
  const host = ssrSafeWindow?.location.origin
  if (!host) return undefined
  const listingUrl = `${host}/marketplace/${app.slug}`

  return reportAbusePath({report: `${listingUrl} (Marketplace Listing)`})
}

export function Resources({app}: ResourcesProps) {
  const linkItems = useMemo(
    () => [
      {url: app.supportUrl, component: CommentDiscussionIcon, text: 'Support'},
      {url: app.pricingUrl, component: ReportIcon, text: 'Pricing'},
      {url: app.documentationUrl, component: BookIcon, text: 'Documentation'},
      {url: app.privacyPolicyUrl, component: LawIcon, text: 'Privacy Policy'},
      {url: app.tosUrl, component: LawIcon, text: 'Terms of Service'},
      {url: app.statusUrl, component: PulseIcon, text: 'Status'},
      {
        url: getReportAbusePath(app),
        component: ReportIcon,
        text: 'Report abuse',
      },
    ],
    [app],
  )
  return (
    <Stack gap={'condensed'} data-testid="apps-resources">
      <SidebarHeading title="Resources" htmlTag={'h2'} />
      <ResourceList linkItems={linkItems} />
    </Stack>
  )
}

try{ Resources.displayName ||= 'Resources' } catch {}