import {Button, type ButtonBaseProps} from '@primer/react'
import {CodeIcon} from '@primer/octicons-react'
import type {Model} from '@github-ui/marketplace-common'
import {sendEvent} from '@github-ui/hydro-analytics'
import {testIdProps} from '@github-ui/test-id-props'
import {GettingStartedButtonClicked} from '../utils/playground-types'

interface GetApiKeyButtonProps
  extends Pick<ButtonBaseProps, 'onClick' | 'variant' | 'block' | 'tabIndex' | 'className'> {
  model: Model
  label?: string
}

export function GetApiKeyButton({label = 'Get API key', model, ...buttonProps}: GetApiKeyButtonProps) {
  const onClick = buttonProps.onClick
  return (
    <Button
      leadingVisual={CodeIcon}
      aria-label={label}
      {...buttonProps}
      onClick={evt => {
        if (onClick) onClick(evt)
        sendEvent(GettingStartedButtonClicked, {
          registry: model.registry,
          model: model.name,
          publisher: model.publisher,
        })
      }}
      {...testIdProps('get-api-key-button')}
    >
      <span className="hide-sm">{label}</span>
    </Button>
  )
}

try{ GetApiKeyButton.displayName ||= 'GetApiKeyButton' } catch {}