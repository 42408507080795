'use strict';

function stableCopy(value) {
  if (!value || typeof value !== 'object') {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(stableCopy);
  }
  var keys = Object.keys(value).sort();
  var stable = {};
  for (var i = 0; i < keys.length; i++) {
    stable[keys[i]] = stableCopy(value[keys[i]]);
  }
  return stable;
}
function hasCycle(value) {
  var parents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Set();
  if (!value || typeof value !== 'object') {
    return false;
  }
  if (parents.has(value)) {
    return true;
  }
  var newParents = new Set(parents);
  newParents.add(value);
  var children = Array.isArray(value) ? value : Object.values(value);
  return children.some(function (v) {
    return hasCycle(v, newParents);
  });
}
module.exports = {
  stableCopy: stableCopy,
  hasCycle: hasCycle
};