import {OverviewHeader} from '../OverviewHeader'
import {About} from './About'
import {SetupButton} from './SetupButton'
import {HeaderActionMenu} from './HeaderActionMenu'
import {Stack} from '@primer/react'
import styles from '../../marketplace.module.css'
import type {AppListing} from '@github-ui/marketplace-common'
import type {PlanInfo} from '../../types'
import {VerifiedOwner} from '../sidebar-shared/VerifiedOwner'
import {WorksWith} from './sidebar/WorksWith'
import {PlanInfo as HeaderPlanInfo} from './sidebar/PlanInfo'
import {Tags} from '../sidebar-shared/Tags'

interface HeaderProps {
  app: AppListing
  planInfo: PlanInfo
  userCanEdit: boolean
}

export function Header(props: HeaderProps) {
  const {app, planInfo, userCanEdit} = props

  return (
    <OverviewHeader
      listing={app}
      listingDetails={<About app={app} />}
      additionalDetails={
        <>
          <Tags tags={app.categories} type="apps" />
          <VerifiedOwner isVerifiedOwner={app.isVerifiedOwner} pageType="apps" />
          <WorksWith isCopilotApp={app.copilotApp} />
          <HeaderPlanInfo planInfo={planInfo} isCopilotApp={app.copilotApp} />
        </>
      }
      callToAction={
        <Stack
          gap={'condensed'}
          direction={'horizontal'}
          className={`width-full width-md-auto ${styles['marketplace-cta-buttons-container']}`}
        >
          <SetupButton planInfo={planInfo} />
          <HeaderActionMenu app={app} planInfo={planInfo} userCanEdit={userCanEdit} />
        </Stack>
      }
    />
  )
}

try{ Header.displayName ||= 'Header' } catch {}