import type {Model} from '@github-ui/marketplace-common'

export function supportsStreaming(model: Model) {
  return model.name !== 'o1-mini' && model.name !== 'o1-preview' && model.name !== 'o1'
}

export function supportsTokenCounting(model: Model) {
  return model.name !== 'o1-mini' && model.name !== 'o1-preview' && model.name !== 'o1'
}

export function supportsStructuredOutput(model: Model) {
  // o1 models currently don't support structured output at all
  if (model.name === 'o1-mini' || model.name === 'o1-preview' || model.name === 'o1') {
    return false
  }

  // all models 'support' JSON output, but some of them are not trained on enough JSON
  // to be able to actually do it without spinning forever and outputting \n tokens
  const publisher = model.publisher.toLowerCase()
  if (publisher === 'openai' || publisher === 'mistral ai') {
    return true
  }

  return false
}

export function supportsJsonSchemaStructuredOutput(model: Model) {
  // currently only GPT-4o supports JSON Schema Structured Output
  return model.name.toLowerCase() === 'gpt-4o'
}

export function supportsStreamingOptions(model: Model): boolean {
  return model.publisher.toLowerCase() === 'openai'
}
