import type {SanitizeAttributeHook} from './extension'

/** @deprecated only used with old renderer */
export const allowAttributesHook =
  (attributes: string[]): SanitizeAttributeHook =>
  (_, data) => {
    if (attributes.includes(data.attrName)) data.forceKeepAttr = true
  }

/** @deprecated only used with old renderer */
export const attributesSelector = (attributes: string[]) => attributes.map(attr => `[${attr}]`).join('')

/**
 * Parse a JSON string property from a props object.
 */
export function parseJsonAttribute<T>(props: Record<`data-${string}`, unknown>, propertyName: `data-${string}`) {
  return propertyName in props && typeof props[propertyName] === 'string'
    ? (JSON.parse(props[propertyName]) as T)
    : null
}

/** Convert kebab-case data- attribute name to camelCase property name for hProperties. */
export const dataAttrToPropName = (attributeName: `data-${string}`) =>
  attributeName.replaceAll(/-\w/g, match => match[1]?.toUpperCase() ?? '')
