import {useMemo} from 'react'
import {PlanForm} from './PlanForm'
import type {PlanInfo, Plan} from '../../../types'
import type {AppListing} from '@github-ui/marketplace-common'

type Props = {
  planInfo: PlanInfo
  listing: AppListing
  selectedPlan?: Plan
}

export function InstallSection({planInfo, listing, selectedPlan}: Props) {
  const installSection = useMemo(() => {
    if (planInfo.isRegularEmuUser) {
      return (
        <div className="pt-3">
          Only enterprise administrators and organization admins can purchase applications from the marketplace.
        </div>
      )
    } else if (planInfo.emuOwnerButNotAdmin) {
      return <div className="pt-3">Only enterprise admins are able to install paid Marketplace plans.</div>
    } else if (selectedPlan) {
      return <PlanForm planInfo={planInfo} listing={listing} plan={selectedPlan} />
    }
  }, [planInfo, selectedPlan, listing])

  return installSection && <div data-testid="install-section">{installSection}</div>
}

try{ InstallSection.displayName ||= 'InstallSection' } catch {}