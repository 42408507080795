import {GitHubAvatar} from '@github-ui/github-avatar'
import type {FeaturedCustomer} from '../../types'
import type {AppListing} from '@github-ui/marketplace-common'
import {assertNever} from '../../utilities/assert-never'
import {joinWords} from '../../utilities/join-words'
import {Link} from '@primer/react'
import {orgHovercardPath} from '@github-ui/paths'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'

export enum TagSectionIdentifier {
  Category = 'Category',
  SupportedLanguages = 'Supported languages',
  FromTheDeveloper = 'From the developer',
  Customers = 'Customers',
}

export function TagSection({
  identifier,
  listing,
  supportedLanguages,
  customers,
}: {
  identifier: TagSectionIdentifier
  listing: AppListing
  supportedLanguages: string[]
  customers: FeaturedCustomer[]
}) {
  const deprecateFeaturedCustomers = useFeatureFlag('deprecate_featured_customers_ui')
  const donotShowFeaturedCustomers = deprecateFeaturedCustomers && identifier === TagSectionIdentifier.Customers
  const content = (() => {
    switch (identifier) {
      case TagSectionIdentifier.Category:
        return <CategorySectionContent listing={listing} />
      case TagSectionIdentifier.SupportedLanguages:
        return <SupportedLanguagesSectionContent supportedLanguages={supportedLanguages} />
      case TagSectionIdentifier.FromTheDeveloper:
        return <FromTheDeveloperSectionContent listing={listing} />
      case TagSectionIdentifier.Customers:
        return <CustomersSectionContent customers={customers} />
      default:
        assertNever(identifier)
    }
  })()

  return donotShowFeaturedCustomers ? null : (
    <div>
      <h3 className="mt-4 f5 fgColor-muted lh-condensed-ultra">{identifier}</h3>
      {identifier === TagSectionIdentifier.FromTheDeveloper ? (
        <div className="mt-2 d-flex flex-column flex-items-start gap-1">{content}</div>
      ) : (
        <div className="mt-2 d-flex flex-wrap gap-1">{content}</div>
      )}
    </div>
  )
}

const CategorySectionContent = ({listing}: {listing: AppListing}) => {
  const primaryCategory = listing.categories[0]
  const secondaryCategory = listing.categories[1]
  return (
    <>
      {primaryCategory && (
        <a
          href={`/marketplace?type=apps&category=${primaryCategory.slug}`}
          className="m-0 topic-tag topic-tag-large topic-tag-link f6"
          data-testid="category-section-primary-category"
        >
          {primaryCategory.name}
        </a>
      )}
      {secondaryCategory && (
        <a
          href={`/marketplace?type=apps&category=${secondaryCategory.slug}`}
          className="m-0 topic-tag topic-tag-large topic-tag-link f6"
          data-testid="category-section-secondary-category"
        >
          {secondaryCategory.name}
        </a>
      )}
    </>
  )
}

const SupportedLanguagesSectionContent = ({supportedLanguages}: {supportedLanguages: string[]}) => {
  const joinedLanguages = joinWords(supportedLanguages)
  return <p data-testid="supported-languages-section">{joinedLanguages}</p>
}

const FromTheDeveloperSectionContent = ({listing}: {listing: AppListing}) => {
  return (
    <>
      {listing.supportUrl && <a href={listing.supportUrl}>Support</a>}
      {listing.statusUrl && <a href={listing.statusUrl}>Status</a>}
      {listing.documentationUrl && <a href={listing.documentationUrl}>Documentation</a>}
      {listing.pricingUrl && <a href={listing.pricingUrl}>Pricing</a>}
      {listing.privacyPolicyUrl && <a href={listing.privacyPolicyUrl}>Privacy Policy</a>}
      {listing.tosUrl && <a href={listing.tosUrl}>Terms of Service</a>}
    </>
  )
}

const CustomersSectionContent = ({customers}: {customers: FeaturedCustomer[]}) => {
  return (
    <>
      {customers.map(customer => (
        <Link
          key={customer.displayLogin}
          href={`/${customer.displayLogin}`}
          data-hovercard-type="organization"
          data-hovercard-url={orgHovercardPath({owner: customer.displayLogin})}
        >
          <GitHubAvatar square sx={{mr: 1, mb: 1}} size={32} src={customer.image || ''} alt={customer.displayLogin} />
        </Link>
      ))}
    </>
  )
}

try{ TagSection.displayName ||= 'TagSection' } catch {}
try{ CategorySectionContent.displayName ||= 'CategorySectionContent' } catch {}
try{ SupportedLanguagesSectionContent.displayName ||= 'SupportedLanguagesSectionContent' } catch {}
try{ FromTheDeveloperSectionContent.displayName ||= 'FromTheDeveloperSectionContent' } catch {}
try{ CustomersSectionContent.displayName ||= 'CustomersSectionContent' } catch {}