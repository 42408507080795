import {Dialog} from '@primer/react/experimental'
import styles from './GettingStartedDialog.module.css'
import {SegmentedControl} from '@primer/react'
import {useState} from 'react'
import {TabGetKey} from './tabs/GettingStartedDialogTabGetKey'
import {TabTemplates} from './tabs/GettingStartedDialogTabTemplate'
import {TabCodespaces} from './tabs/GettingStartedDialogTabCodespaces'

enum GettingStartedContentOption {
  GetKey = 0,
  Templates = 1,
  Codespaces = 2,
}

export interface GettingStartedDialogTabbedProps {
  onClose: () => void
  modelName: string
}

export function GettingStartedDialogTabbed({onClose, modelName}: GettingStartedDialogTabbedProps) {
  return (
    <Dialog
      title={`Use ${modelName}`}
      position={{narrow: 'fullscreen', regular: 'right'}}
      onClose={onClose}
      className={styles.gettingStartedDialog}
      renderBody={() => <DialogBody />}
    />
  )
}

function DialogBody() {
  const [selectedOption, setSelectedOption] = useState<GettingStartedContentOption>(GettingStartedContentOption.GetKey)

  const handleOptionChange = (option: GettingStartedContentOption) => {
    if (option === selectedOption) return
    setSelectedOption(option)
  }

  return (
    <div className="p-3">
      <SegmentedControl
        size="small"
        fullWidth={{narrow: true, regular: false}}
        aria-label="Mode"
        onChange={handleOptionChange}
      >
        <SegmentedControl.Button selected={selectedOption === GettingStartedContentOption.GetKey}>
          Get API Key
        </SegmentedControl.Button>
        <SegmentedControl.Button selected={selectedOption === GettingStartedContentOption.Templates}>
          Start with a template
        </SegmentedControl.Button>
        <SegmentedControl.Button selected={selectedOption === GettingStartedContentOption.Codespaces}>
          Create Codespace
        </SegmentedControl.Button>
      </SegmentedControl>
      <div className="mt-3">
        {selectedOption === GettingStartedContentOption.GetKey && <TabGetKey />}
        {selectedOption === GettingStartedContentOption.Templates && <TabTemplates />}
        {selectedOption === GettingStartedContentOption.Codespaces && <TabCodespaces />}
      </div>
    </div>
  )
}

try{ GettingStartedDialogTabbed.displayName ||= 'GettingStartedDialogTabbed' } catch {}
try{ DialogBody.displayName ||= 'DialogBody' } catch {}