"use strict";

var _asyncToGenerator = require("@babel/runtime/helpers/asyncToGenerator").default;
var _require = require('./observeFragmentExperimental'),
  observeFragment = _require.observeFragment;
function waitForFragmentData(_x, _x2, _x3) {
  return _waitForFragmentData.apply(this, arguments);
}
function _waitForFragmentData() {
  _waitForFragmentData = _asyncToGenerator(function* (environment, fragment, fragmentRef) {
    var subscription;
    try {
      var _subscription;
      var data = yield new Promise(function (resolve, reject) {
        subscription = observeFragment(environment, fragment, fragmentRef).subscribe({
          next: function next(val) {
            if (val.state === 'ok') {
              resolve(val.value);
            } else if (val.state === 'error') {
              reject(val.error);
            }
          }
        });
      });
      (_subscription = subscription) === null || _subscription === void 0 ? void 0 : _subscription.unsubscribe();
      return data;
    } catch (e) {
      var _subscription2;
      (_subscription2 = subscription) === null || _subscription2 === void 0 ? void 0 : _subscription2.unsubscribe();
      throw e;
    }
  });
  return _waitForFragmentData.apply(this, arguments);
}
module.exports = {
  waitForFragmentData: waitForFragmentData
};