import {Heading, Stack} from '@primer/react'
import {ChevronDownIcon, ChevronRightIcon} from '@primer/octicons-react'
import {useState} from 'react'

export function Revealer({title, children}: {title: string; children: React.ReactNode}) {
  const [showContent, setShowContent] = useState(false)

  return (
    <details key={title} className="border-top color-border-default pl-2">
      <Stack
        as="summary"
        gap="condensed"
        direction="horizontal"
        align="center"
        onClick={() => setShowContent(!showContent)}
        className="cursor-pointer my-3"
      >
        {showContent ? (
          <ChevronDownIcon size={12} className="fgColor-muted" />
        ) : (
          <ChevronRightIcon size={12} className="fgColor-muted" />
        )}
        <Heading variant="small" as="h3">
          {title}
        </Heading>
      </Stack>
      {showContent && <div className="mb-2">{children}</div>}
    </details>
  )
}

try{ Revealer.displayName ||= 'Revealer' } catch {}