import {useMemo, useState} from 'react'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import type {PlanInfo, Plan} from '../../../types'
import type {AppListing} from '@github-ui/marketplace-common'
import {AccountSelector} from './AccountSelector'
import {Link, TextInput, Stack, FormControl} from '@primer/react'
import {useCSRFToken} from '@github-ui/use-csrf-token'
import {InstallButton} from './InstallButton'
import {InstallHelpText} from './InstallHelpText'

type Props = {
  planInfo: PlanInfo
  listing: AppListing
  plan: Plan
}

export function PlanForm({planInfo, listing, plan}: Props) {
  const [seatQuantity, setSeatQuantity] = useState(planInfo.orderPreview?.quantity || 1)
  const marketplaceFreeInstallFlag = useFeatureFlag('marketplace_free_install')
  const skipOrderReview = useMemo(() => {
    // For Copilot Extensions, we skip the order review stage since they're free
    if (marketplaceFreeInstallFlag && listing.copilotApp && !plan.isPaid) {
      return true
    }

    // For direct billing plans, we skip the order review stage since payment is setup outside of GitHub
    return plan.directBilling
  }, [listing.copilotApp, marketplaceFreeInstallFlag, plan.directBilling, plan.isPaid])

  const authToken = useCSRFToken(`/marketplace/${listing.slug}/order/${plan.id}`, 'post')

  return (
    <form
      method={skipOrderReview ? 'POST' : 'GET'}
      action={`/marketplace/${listing.slug}/order/${plan.id}`}
      data-testid="plan-form"
      id="plan-form"
    >
      <input type="hidden" name="quantity" id="quantity" value="1" />
      {skipOrderReview && (
        <>
          {
            // eslint-disable-next-line github/authenticity-token
            <input type="hidden" name="authenticity_token" value={authToken} data-testid="csrf-token" />
          }
          <input type="hidden" name="quantity" id="quantity" value="1" />
          {planInfo.canSignEndUserAgreement && planInfo.endUserAgreement && (
            <>
              <input type="hidden" name="marketplace_listing_id" id="marketplace_listing_id" value={listing.id} />
              <input
                type="hidden"
                name="marketplace_agreement_id"
                id="marketplace_agreement_id"
                value={planInfo.endUserAgreement.id}
              />
            </>
          )}
        </>
      )}
      <Stack gap="normal" className={plan.isPaid && !plan.perUnit ? '' : 'pt-3'}>
        {plan.isPaid && (
          <>
            {plan.perUnit && (
              <FormControl id="quantity">
                <FormControl.Label htmlFor="quantity" className="text-normal" as="label">
                  {`Number of ${plan.unitName}s`}
                </FormControl.Label>
                <TextInput
                  type="number"
                  value={seatQuantity}
                  name="quantity"
                  min="0"
                  max="100000"
                  sx={{width: '6rem'}}
                  onChange={e => setSeatQuantity(parseInt(e.target.value))}
                />{' '}
              </FormControl>
            )}
            <div className={'border-bottom border-top py-2 d-flex flex-items-baseline flex-justify-between'}>
              <div className="d-flex flex-items-baseline">
                <span className="f2 mr-2">{plan.price}</span>
                <span className="text color-fg-muted">
                  {plan.perUnit && <span> {`/ ${plan.unitName}`}</span>}
                  <span>{planInfo.isUserBilledMonthly ? ' / month' : ' / year'}</span>
                </span>
              </div>
            </div>
          </>
        )}

        {listing.copilotApp && (
          <p className="mb-0">
            Copilot extensions are currently in{' '}
            <Link href="https://docs.github.com/en/site-policy/github-terms/github-pre-release-license-terms" inline>
              preview
            </Link>{' '}
            for all Copilot users. Supported organizations are indicated.
          </p>
        )}

        <AccountSelector planInfo={planInfo} listing={listing} />
        <div>
          <InstallButton planInfo={planInfo} plan={plan} listing={listing} />
          <InstallHelpText planInfo={planInfo} plan={plan} listing={listing} />
        </div>
      </Stack>
    </form>
  )
}

try{ PlanForm.displayName ||= 'PlanForm' } catch {}