import {Heading, Text, Link} from '@primer/react'
import {Revealer} from './Revealer'

export function TransparencySection() {
  const infoSections = [
    {
      title: '1. Publisher',
      content: 'Publisher Information',
    },
    {
      title: '2. Permissions',
      content: 'Permissions Information',
    },
    {
      title: '3. Security & Compliance',
      content: 'Security & Compliance Information',
    },
  ]

  return (
    <div data-testid="transparency-section">
      <Heading as="h2" variant="medium" className="pb-3">
        Transparency and security
      </Heading>
      {infoSections.map(section => (
        <Revealer key={section.title} title={section.title}>
          {section.content}
        </Revealer>
      ))}
      <div className="border-top color-border-muted pt-3">
        <Text size="small" className="fgColor-muted">
          For more information on the terms of service on the GitHub Marketplace, please visit the{' '}
          <Link
            inline
            href="https://docs.github.com/en/site-policy/github-terms/github-marketplace-developer-agreement"
          >
            Marketplace Developer Agreement
          </Link>
          .
        </Text>
      </div>
    </div>
  )
}

try{ TransparencySection.displayName ||= 'TransparencySection' } catch {}