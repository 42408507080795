import {Dialog} from '@primer/react/experimental'
import type {GettingStarted} from '../../../../types'
import {CodeContainer} from './CodeContainer'
import styles from './GettingStartedDialog.module.css'
import type {ModelPersistentUIState} from '../../../../utils/playground-local-storage'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {GettingStartedDialogTabbed} from './GettingStartedDialogTabbed'

interface GettingStartedDialogProps {
  onClose: () => void
  openInCodespaceUrl: string
  showCodespacesSuggestion: boolean
  gettingStarted: GettingStarted
  modelName: string
  uiState?: ModelPersistentUIState
  setUiState?: (uiState: ModelPersistentUIState) => void
}

export default function GettingStartedDialog({
  onClose,
  openInCodespaceUrl,
  showCodespacesSuggestion,
  gettingStarted,
  modelName,
  uiState,
  setUiState,
}: GettingStartedDialogProps) {
  const shouldRenderNewDialog = useFeatureFlag('github_models_new_docs')
  if (shouldRenderNewDialog) {
    return <GettingStartedDialogTabbed onClose={onClose} modelName={modelName} />
  }

  return (
    <Dialog
      title="Get API key"
      position={{narrow: 'fullscreen', regular: 'center'}}
      onClose={onClose}
      className={styles.gettingStartedDialog}
      renderBody={() => {
        return (
          <CodeContainer
            openInCodespaceUrl={openInCodespaceUrl}
            showCodespacesSuggestion={showCodespacesSuggestion}
            gettingStarted={gettingStarted}
            modelName={modelName}
            uiState={uiState}
            setUiState={setUiState}
          />
        )
      }}
    />
  )
}

try{ GettingStartedDialog.displayName ||= 'GettingStartedDialog' } catch {}