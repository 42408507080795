'use strict';

var resolverDataInjector = require('./store/live-resolvers/resolverDataInjector');
var _require = require('./store/observeFragmentExperimental'),
  observeFragment = _require.observeFragment;
var _require2 = require('./store/waitForFragmentExperimental'),
  waitForFragmentData = _require2.waitForFragmentData;
function isValueResult(input) {
  return input.ok === true;
}
function isErrorResult(input) {
  return input.ok === false;
}
module.exports = {
  resolverDataInjector: resolverDataInjector,
  isValueResult: isValueResult,
  isErrorResult: isErrorResult,
  observeFragment: observeFragment,
  waitForFragmentData: waitForFragmentData
};