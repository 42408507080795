import {PlaygroundChat} from './PlaygroundChat'
import {PlaygroundInputs} from './PlaygroundInputs'
import {useCallback, useEffect, useState} from 'react'
import {getDefaultUiState, setLocalStorageUiState} from '../../../utils/playground-local-storage'
import {useResponsiveValue} from '@primer/react'
import {PlaygroundHeader} from './PlaygroundHeader'
import {GiveFeedback} from '../../../components/GiveFeedback'
import {MessageHistoryProvider} from './MessageHistoryContext'
import ModelSwitcher from './ModelSwitcher'
import {useNavigate} from '@github-ui/use-navigate'
import {ModelUrlHelper} from '../../../utils/model-url-helper'
import {CheckIcon, MarkGithubIcon} from '@primer/octicons-react'
import type {GettingStartedPayload, ModelState, SidebarSelectionOptions} from '../../../types'
import ModelItem from '../../../components/ModelItem'
import commonStyles from '@github-ui/marketplace-common/marketplace-common.module.css'
import {useModelClient} from '../contexts/ModelClientContext'
import styles from './Playground.module.css'
import {usePlaygroundState} from '../../../contexts/PlaygroundStateContext'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'

export type PlaygroundProps = {
  modelState?: ModelState
  position: number
}

export function Playground({modelState, position}: PlaygroundProps) {
  const {featuredModels = []} = useRoutePayload<GettingStartedPayload>()
  const storedDefaults = getDefaultUiState(modelState?.gettingStarted)
  const modelClient = useModelClient()
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false)
  const [uiState, setUiState] = useState(storedDefaults)
  const isMobile = useResponsiveValue({narrow: true}, false)
  const {models} = usePlaygroundState()
  const onComparisonMode = models.length > 1

  const navigate = useNavigate()

  const handleSetSidebarTab = (value: SidebarSelectionOptions) => {
    setLocalStorageUiState({...uiState, sidebarTab: value})
    setUiState(prevState => ({...prevState, sidebarTab: value}))
    setShowSidebarOnMobile(true)
  }

  const handleShowSidebar = (value: boolean) => {
    setUiState(prevState => ({...prevState, showSidebar: value}))
    setLocalStorageUiState({...uiState, showSidebar: value})
  }

  const handleSelectLanguage = useCallback(
    (language: string) => {
      const languageEntry = modelState?.gettingStarted[language]
      if (!languageEntry) return
      const availableSDK = Object.keys(languageEntry.sdks).includes(uiState.preferredSdk)
        ? uiState.preferredSdk
        : Object.keys(languageEntry.sdks)[0] || ''
      setUiState(prevState => ({...prevState, preferredLanguage: language, preferredSdk: availableSDK}))
      setLocalStorageUiState({
        ...uiState,
        preferredLanguage: language,
        preferredSdk: availableSDK,
      })
    },
    [modelState?.gettingStarted, uiState],
  )

  const handleSelectSDK = useCallback(
    (sdk: string) => {
      setUiState(prevState => ({...prevState, preferredSdk: sdk}))
      setLocalStorageUiState({
        ...uiState,
        preferredSdk: sdk,
      })
    },
    [uiState],
  )

  const stopStreamingMessages = useCallback(() => modelClient.stopStreamingMessages(position), [modelClient, position])

  // Stop streaming messages when the model changes
  useEffect(() => {
    return stopStreamingMessages
  }, [stopStreamingMessages, modelState?.catalogData?.name])

  return (
    <div className={`flex-1 d-flex flex-column height-fit`} style={{minWidth: onComparisonMode ? '560px' : undefined}}>
      {modelState ? (
        <PlaygroundHeader
          model={modelState?.catalogData}
          modelInputSchema={modelState?.modelInputSchema}
          position={position}
          gettingStarted={modelState?.gettingStarted}
          uiState={uiState}
          setUiState={setUiState}
          handleSetSidebarTab={handleSetSidebarTab}
        />
      ) : (
        <div className="d-flex flex-justify-between mb-3">
          <ModelSwitcher
            onSelect={async m => navigate({pathname: ModelUrlHelper.playgroundUrl(m)})}
            onComparisonMode={onComparisonMode}
            handleSetSidebarTab={handleSetSidebarTab}
          />
          {!isMobile && <GiveFeedback />}
        </div>
      )}
      <div
        className={`flex-1 d-flex flex-column flex-md-row border ${
          modelState ? 'overflow-hidden' : '' // we want to hide overflow only on the chat view, not the landing page
        } ${onComparisonMode ? 'rounded-bottom-2' : 'rounded-2'}`}
      >
        {modelState ? (
          <>
            <MessageHistoryProvider>
              <PlaygroundChat
                model={modelState}
                position={position}
                stopStreamingMessages={stopStreamingMessages}
                showSidebar={uiState.showSidebar}
                onComparisonMode={onComparisonMode}
                handleSetSidebarTab={handleSetSidebarTab}
                handleShowSidebar={handleShowSidebar}
                uiState={uiState}
                handleSelectLanguage={handleSelectLanguage}
                handleSelectSDK={handleSelectSDK}
              />
            </MessageHistoryProvider>
            {!onComparisonMode && (
              <PlaygroundInputs
                model={modelState}
                position={position}
                sidebarTab={uiState.sidebarTab}
                showSidebar={uiState.showSidebar}
                showSidebarOnMobile={showSidebarOnMobile}
                handleSetSidebarTab={handleSetSidebarTab}
                handleShowSidebar={handleShowSidebar}
                handleShowSidebarOnMobile={setShowSidebarOnMobile}
              />
            )}
          </>
        ) : (
          <div className="flex-1 d-flex flex-column flex-justify-center flex-items-center p-3">
            <MarkGithubIcon size={64} className="pb-3" />
            <p className="h4">Welcome to GitHub Models</p>
            <p className="color-fg-muted">
              A catalog and playground of AI models to help you build AI features and products.
            </p>
            <p>
              <span className="fgColor-success">
                <CheckIcon size={16} />
              </span>{' '}
              <span className="text-bold">Model switching:</span> A single API key for all models &amp; billing.
            </p>
            <p>
              <span className="fgColor-success">
                <CheckIcon size={16} />
              </span>{' '}
              <span className="text-bold">Quick personal setup:</span> GitHub PAT to install models in your projects.
            </p>
            <p>
              <span className="fgColor-success">
                <CheckIcon size={16} />
              </span>{' '}
              <span className="text-bold">Free to start:</span> No charges until you hit our rate limits.
            </p>
            <p className="pt-10">
              Select a model to get started, or{' '}
              <a className="Link--inTextBlock" href="/marketplace/models/catalog">
                explore the full model catalog
              </a>
              .
            </p>
            <div className={`pt-3 ${commonStyles['marketplace-featured-grid']} ${styles.featuredGrid}`}>
              {featuredModels?.map(model => (
                <ModelItem
                  key={model.id}
                  model={model}
                  href={`/marketplace/models/${model.registry}/${model.name}/playground`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

try{ Playground.displayName ||= 'Playground' } catch {}