import {Button, useResponsiveValue} from '@primer/react'
import type {PlanInfo} from '../../types'

interface SetupButtonProps {
  planInfo: PlanInfo
}

export function SetupButton(props: SetupButtonProps) {
  const {planInfo} = props
  const hideButton = planInfo.viewerHasPurchased && planInfo.viewerHasPurchasedForAllOrganizations
  const isMobile = useResponsiveValue({narrow: true}, false) as boolean

  return (
    !hideButton && (
      <Button
        as="a"
        variant="primary"
        className="js-smoothscroll-anchor width-full"
        href="#pricing-and-setup"
        data-testid="setup-button"
        size={isMobile ? 'medium' : 'large'}
      >
        {planInfo.viewerHasPurchased || planInfo.anyOrgsPurchased
          ? 'Add'
          : planInfo.plans.some(plan => plan.hasFreeTrial)
            ? 'Set up a free trial'
            : 'Add'}
      </Button>
    )
  )
}

try{ SetupButton.displayName ||= 'SetupButton' } catch {}