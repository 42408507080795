export function filterStringFromQuery(
  type: string | null,
  copilotApp: string | null,
  query: string | null,
  category: string | null,
): string {
  // return in:{type} if type is found, in:copilot if copilotApp is found or empty string if neither,
  // then append the query if found
  return `${type && !copilotApp ? `in:${type}${category ? `/${category}` : ''}` : copilotApp ? 'in:copilot' : ''}${
    query ? ` ${query}` : ''
  }`
}

type MarketplaceQuery = {
  type: string | null
  copilotApp: string | null
  query: string | null
  category: string | null
}

export function queryFromFilterString(filterString: string): MarketplaceQuery {
  const result: MarketplaceQuery = {
    type: null,
    copilotApp: null,
    query: null,
    category: null,
  }
  const queryIn = filterString.match(/in:([\w/-]+)/)?.[1]
  if (queryIn) {
    if (queryIn === 'copilot') {
      result.copilotApp = 'true'
      result.type = 'apps'
    } else {
      const [queryType, queryCategory] = queryIn.split('/')
      result.type = queryType || null
      result.category = queryCategory || null
    }
  }
  result.query = filterString.replace(/in:[\w/-]*/, '').trim()

  return result
}
