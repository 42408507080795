'use strict';

var invariant = require('invariant');
var _require = require('relay-runtime'),
  ConnectionInterface = _require.ConnectionInterface,
  getValueAtPath = _require.getValueAtPath;
function getConnectionState(direction, fragmentNode, fragmentData, connectionPathInFragmentData) {
  var _pageInfo$END_CURSOR, _pageInfo$START_CURSO;
  var _ConnectionInterface$ = ConnectionInterface.get(),
    EDGES = _ConnectionInterface$.EDGES,
    PAGE_INFO = _ConnectionInterface$.PAGE_INFO,
    HAS_NEXT_PAGE = _ConnectionInterface$.HAS_NEXT_PAGE,
    HAS_PREV_PAGE = _ConnectionInterface$.HAS_PREV_PAGE,
    END_CURSOR = _ConnectionInterface$.END_CURSOR,
    START_CURSOR = _ConnectionInterface$.START_CURSOR;
  var connection = getValueAtPath(fragmentData, connectionPathInFragmentData);
  if (connection == null) {
    return {
      cursor: null,
      hasMore: false
    };
  }
  !(typeof connection === 'object') ? process.env.NODE_ENV !== "production" ? invariant(false, 'Relay: Expected connection in fragment `%s` to have been `null`, or ' + 'a plain object with %s and %s properties. Instead got `%s`.', fragmentNode.name, EDGES, PAGE_INFO, connection) : invariant(false) : void 0;
  var edges = connection[EDGES];
  var pageInfo = connection[PAGE_INFO];
  if (edges == null || pageInfo == null) {
    return {
      cursor: null,
      hasMore: false
    };
  }
  !Array.isArray(edges) ? process.env.NODE_ENV !== "production" ? invariant(false, 'Relay: Expected connection in fragment `%s` to have a plural `%s` field. ' + 'Instead got `%s`.', fragmentNode.name, EDGES, edges) : invariant(false) : void 0;
  !(typeof pageInfo === 'object') ? process.env.NODE_ENV !== "production" ? invariant(false, 'Relay: Expected connection in fragment `%s` to have a `%s` field. ' + 'Instead got `%s`.', fragmentNode.name, PAGE_INFO, pageInfo) : invariant(false) : void 0;
  var cursor = direction === 'forward' ? (_pageInfo$END_CURSOR = pageInfo[END_CURSOR]) !== null && _pageInfo$END_CURSOR !== void 0 ? _pageInfo$END_CURSOR : null : (_pageInfo$START_CURSO = pageInfo[START_CURSOR]) !== null && _pageInfo$START_CURSO !== void 0 ? _pageInfo$START_CURSO : null;
  !(cursor === null || typeof cursor === 'string') ? process.env.NODE_ENV !== "production" ? invariant(false, 'Relay: Expected page info for connection in fragment `%s` to have a ' + 'valid `%s`. Instead got `%s`.', fragmentNode.name, START_CURSOR, cursor) : invariant(false) : void 0;
  var hasMore;
  if (direction === 'forward') {
    hasMore = cursor != null && pageInfo[HAS_NEXT_PAGE] === true;
  } else {
    hasMore = cursor != null && pageInfo[HAS_PREV_PAGE] === true;
  }
  return {
    cursor: cursor,
    hasMore: hasMore
  };
}
module.exports = getConnectionState;