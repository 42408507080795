import {useEffect} from 'react'
import {Dialog, FormControl, Spinner, Textarea} from '@primer/react'
import {testIdProps} from '@github-ui/test-id-props'
import type {Model} from '@github-ui/marketplace-common'
import {useModelClient} from '../contexts/ModelClientContext'
import {useImprovePrompt} from '../hooks/use-improve-prompt'

interface ImprovePromptConfirmationDialogProps {
  onClose: () => void
  handleUpdateSystemPrompt: () => void
  promptSuggestionText: string
  currentPrompt: string
  setImprovedPromptText: (value: string) => void
  improvedPromptText: string
  improvedSysPromptModel: Model
}

export function ImprovePromptConfirmationDialog({
  onClose,
  handleUpdateSystemPrompt,
  promptSuggestionText,
  currentPrompt,
  setImprovedPromptText,
  improvedPromptText,
  improvedSysPromptModel,
}: ImprovePromptConfirmationDialogProps) {
  const modelClient = useModelClient()

  const {generatedPrompt, isLoading} = useImprovePrompt(
    currentPrompt,
    promptSuggestionText,
    modelClient,
    improvedSysPromptModel,
  )

  useEffect(() => {
    setImprovedPromptText(generatedPrompt)
  }, [generatedPrompt, setImprovedPromptText])

  return (
    <Dialog
      width="large"
      onClose={onClose}
      title={<div {...testIdProps('prompt-dialog-confirmation')}>Improve prompt</div>}
      footerButtons={[
        {
          buttonType: 'default',
          content: 'Cancel',
          onClick: onClose,
        },
        {
          buttonType: 'primary',
          content: 'Use improved prompt',
          onClick: handleUpdateSystemPrompt,
        },
      ]}
    >
      Apply the improved prompt or suggest more improvement iterations.
      <div className="py-3 d-flex flex-column gap-3">
        <FormControl>
          <FormControl.Label className="d-flex flex-items-center gap-2">
            Improved prompt
            {isLoading && <Spinner size="small" srText="Loading improved prompt" />}
          </FormControl.Label>

          <Textarea
            value={improvedPromptText}
            onChange={e => setImprovedPromptText(e.target.value)}
            className="width-full"
          />
        </FormControl>
      </div>
    </Dialog>
  )
}

try{ ImprovePromptConfirmationDialog.displayName ||= 'ImprovePromptConfirmationDialog' } catch {}