import {visit} from 'unist-util-visit'
import type {
  AfterSanitizeAttributeHook,
  CopilotMarkdownExtension,
  RehypeHtmlTransformer,
  SanitizeAttributeHook,
} from '../extension'

const hovercardUrlAttribute = 'data-hovercard-url'

const allowHovercardAttributes: SanitizeAttributeHook = (node, data) => {
  switch (data.attrName) {
    case hovercardUrlAttribute:
      data.forceKeepAttr = true
      break
    // Allow href attributes on hovercard elements
    case 'href':
      data.forceKeepAttr = node.hasAttribute(hovercardUrlAttribute)
      break
  }
}

/** Set all elements owning target to target=_blank if in immersive */
const openLinksInNewTab: AfterSanitizeAttributeHook = node => {
  if ('target' in node || node instanceof HTMLAnchorElement) {
    node.setAttribute('target', '_blank')
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute('rel', 'noopener noreferrer')
  }

  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute('target') &&
    // eslint-disable-next-line github/get-attribute
    (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
  ) {
    // eslint-disable-next-line github/get-attribute
    node.setAttribute('xlink:show', 'new')
  }
}

const hastOpenLinksInNewTab: RehypeHtmlTransformer = tree =>
  visit(tree, node => {
    if (node.type !== 'element') return

    if (node.tagName === 'a' || 'target' in node.properties) {
      node.properties.target = '_blank'
      node.properties.rel = 'noopener noreferrer'
    }

    if (!('target' in node.properties) && ('xlink:href' in node.properties || 'href' in node.properties)) {
      node.properties['xlink:show'] = 'new'
    }
  })

interface LinksExtensionOptions {
  openLinksInCurrentTab?: boolean
}

export default function linksExtension({openLinksInCurrentTab}: LinksExtensionOptions): CopilotMarkdownExtension {
  return {
    sanitizer: {
      attributeHook: allowHovercardAttributes,
      afterAttributesHook: (...args) => {
        if (!openLinksInCurrentTab) openLinksInNewTab(...args)
      },
    },
    transformHtml: openLinksInCurrentTab ? undefined : hastOpenLinksInNewTab,
  }
}
