'use strict';

var useFragmentInternal = require('./useFragmentInternal');
var useStaticFragmentNodeWarning = require('./useStaticFragmentNodeWarning');
var _require = require('react'),
  useDebugValue = _require.useDebugValue;
var _require2 = require('relay-runtime'),
  getFragment = _require2.getFragment;
function useFragment(fragment, key) {
  var fragmentNode = getFragment(fragment);
  useStaticFragmentNodeWarning(fragmentNode, 'first argument of useFragment()');
  var data = useFragmentInternal(fragmentNode, key, 'useFragment()');
  if (process.env.NODE_ENV !== "production") {
    useDebugValue({
      fragment: fragmentNode.name,
      data: data
    });
  }
  return data;
}
module.exports = useFragment;