import {useFilterContext} from '../contexts/FilterContext'
import {useCallback, useMemo, useState} from 'react'
import {debounce} from '@github/mini-throttle'
import {Filter, type FilterQuery} from '@github-ui/filter'
import styles from './marketplace-search-field.module.css'
import {filterStringFromQuery, queryFromFilterString} from '../utilities/filters'
import {ListingTypeFilterProvider} from '../utilities/filter-providers'

const providers = [ListingTypeFilterProvider]

export default function MarketplaceSearchField() {
  const {
    query,
    onQueryChange: onQueryChangeV2,
    setType,
    setCopilotApp,
    setCategory,
    type,
    copilotApp,
    category,
  } = useFilterContext()
  const initialFilter = useMemo(
    () => filterStringFromQuery(type, copilotApp, query, category),
    [query, type, copilotApp, category],
  )
  const [privateQuery, setPrivateQuery] = useState<string>(initialFilter)

  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetQuery = useCallback(
    debounce((filterStr: string) => {
      const {
        type: newType,
        copilotApp: newCopilotApp,
        query: newQuery,
        category: newCategory,
      } = queryFromFilterString(filterStr)
      setType(newType)
      setCopilotApp(newCopilotApp)
      setCategory(newCategory)
      onQueryChangeV2(newQuery || '')
    }, 400),
    [onQueryChangeV2],
  )

  const handleQueryChange = useCallback(
    (value: string) => {
      setPrivateQuery(value)
      debouncedSetQuery(value)
    },
    [debouncedSetQuery],
  )

  const handleSubmit = useCallback(
    (filterQuery: FilterQuery) => {
      handleQueryChange(filterQuery.raw)
    },
    [handleQueryChange],
  )

  return (
    <Filter
      id="marketplace-search-filter"
      data-testid="marketplace-search-filter"
      providers={providers}
      label="Search Marketplace"
      variant="input"
      filterValue={privateQuery}
      onChange={handleQueryChange}
      placeholder="Search for Copilot extensions, apps, actions, and models"
      className={`${styles['search-input']}`}
      settings={{
        disableAdvancedTextFilter: true,
        groupAndKeywordSupport: false,
      }}
      onSubmit={handleSubmit}
    />
  )
}

try{ MarketplaceSearchField.displayName ||= 'MarketplaceSearchField' } catch {}