import {useState} from 'react'
import {useResponsiveValue, LinkButton} from '@primer/react'
import {CommandPaletteIcon, LinkExternalIcon} from '@primer/octicons-react'
import type {GettingStarted, ShowModelPayload} from '../../../types'
import type {Model} from '@github-ui/marketplace-common'
import {templateRepositoryNwo} from '../../../utils/playground-types'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ModelDetails} from '../../../components/ModelDetailsSidebar/ModelDetails'
import {ModelUrlHelper} from '../../../utils/model-url-helper'
import GettingStartedDialog from '../../playground/components/GettingStartedDialog/GettingStartedDialog'
import {BreadcrumbHeader} from './BreadcrumbHeader'
import {GiveFeedback} from '../../../components/GiveFeedback'
import {ModelsAvatar} from '../../../components/ModelsAvatar'
import {GetApiKeyButton} from '../../../components/GetApiKeyButton'

export function ModelOverviewHeader({
  model,
  gettingStarted,
  canUseModel,
}: {
  model: Model
  gettingStarted: GettingStarted
  canUseModel: boolean
}) {
  const {summary} = model
  const openInCodespacesUrl = `/codespaces/new?template_repository=${templateRepositoryNwo}`
  const [isGettingStartedDialogOpen, setGettingStartedDialogOpen] = useState(false)
  const {canUseO1Models, getApiKeyButtonLabel} = useRoutePayload<ShowModelPayload>()

  const isMobile = useResponsiveValue({narrow: true}, false) as boolean
  const iconSize = isMobile ? 24 : 32

  const isO1Model = ['o1-mini', 'o1-preview', 'o1'].includes(model.name)
  const showAPIKey = canUseO1Models || !isO1Model

  const showCodespacesSuggestion = isO1Model ? canUseO1Models : true

  return (
    <div className={`d-flex flex-column gap-3 ${isMobile && 'p-3 border-bottom'}`}>
      <BreadcrumbHeader model={model} />
      <div className={`d-flex gap-3 ${isMobile ? 'flex-column' : 'flex-row'}`}>
        <div className="width-full d-flex gap-2 align-items-left">
          <ModelsAvatar model={model} size={iconSize} />
          <h1 className={isMobile ? 'h4' : 'h3'}>{model.friendly_name}</h1>
        </div>

        {isMobile && <span>{summary}</span>}
        {isMobile && <ModelDetails model={model} direction="row" />}

        <div className={`d-flex gap-2 ${isMobile && 'width-full'}`}>
          {!isMobile && <GiveFeedback />}
          <div className={`flex-1 d-flex gap-2 ${isMobile ? 'flex-column' : 'flex-row'}`}>
            {canUseModel && (
              <LinkButton
                variant="default"
                block={isMobile}
                href={ModelUrlHelper.playgroundUrl(model)}
                leadingVisual={CommandPaletteIcon}
                tabIndex={0}
              >
                Playground
              </LinkButton>
            )}
            {model.static_model ? (
              <LinkButton
                block={isMobile}
                leadingVisual={LinkExternalIcon}
                variant="primary"
                href={'https://aka.ms/oai/docs/limited-access-models'}
                tabIndex={0}
              >
                Sign up for early access on Azure
              </LinkButton>
            ) : (
              showAPIKey && (
                <GetApiKeyButton
                  tabIndex={0}
                  label={getApiKeyButtonLabel}
                  block={isMobile}
                  variant="primary"
                  model={model}
                  onClick={() => setGettingStartedDialogOpen(true)}
                />
              )
            )}
          </div>
          {isGettingStartedDialogOpen && (
            <GettingStartedDialog
              openInCodespaceUrl={openInCodespacesUrl}
              onClose={() => setGettingStartedDialogOpen(false)}
              showCodespacesSuggestion={showCodespacesSuggestion}
              gettingStarted={gettingStarted}
              modelName={model.name}
            />
          )}
        </div>
      </div>
    </div>
  )
}

try{ ModelOverviewHeader.displayName ||= 'ModelOverviewHeader' } catch {}