import type {Attachment as FileElementAttachment} from '@github/file-attachment-element'
import React from 'react'

import invariant from 'tiny-invariant'
import {pluralize} from '../../../../utils/pluralize'
import {ACCEPTED_MIME_REGEX, MAX_ATTACHMENT_COUNT, MAX_ATTACHMENT_SIZE} from './constants'
import type {Attachment, FileAttachment} from './attachment'
import {useSubscribedHandles} from './use-subscribed-handles'

declare global {
  interface HTMLElementEventMap {
    'file-attachment-accept': CustomEvent<{attachments: FileElementAttachment[]}>
  }
}

type AttachmentState = {
  attachments: Attachment[]
  errorMessage?: string
}

type AttachmentAPI = {
  addFiles(files: FileAttachment[]): void
  remove(attachment: Attachment): void
  reset(): void
}

type AttachmentsContext = [state: AttachmentState & {attachLimit: number}, api: AttachmentAPI]

const eventSingleton = new EventTarget()
const attachmentContext = React.createContext<AttachmentsContext | null>(null)

export function AttachmentsProvider(
  props: React.PropsWithChildren<{
    attachLimit?: number
    subscribed?: boolean
  }>,
) {
  const attachLimit = props.attachLimit ?? MAX_ATTACHMENT_COUNT

  type Actions =
    | {type: 'new_files'; files: FileAttachment[]}
    | {type: 'remove'; attachment: Attachment}
    | {type: 'reset'}
  const [state, dispatch] = React.useReducer(
    (prevState: AttachmentState, action: Actions): AttachmentState => {
      switch (action.type) {
        case 'new_files': {
          if (action.files.length === 0) return prevState

          const files = filterUnsupportedFiles(action.files.map(f => f.file))
          if (files.length === 0)
            return {errorMessage: "Sorry, it look's like that file was not supported.", attachments: []}
          if (areFilesTooLarge(files)) return {errorMessage: 'Sorry, we only support files up to 1MB.', attachments: []}

          if (files.length > attachLimit) {
            return {
              errorMessage: `Sorry, only up to ${pluralize('file', attachLimit)} per message are supported.`,
              attachments: [],
            }
          }
          return {attachments: action.files}
        }
        case 'remove':
          return {attachments: prevState.attachments.filter(a => a !== action.attachment)}
        case 'reset':
        default:
          return {attachments: []}
      }
    },
    {attachments: []},
  )

  // The public api
  const api = useSubscribedHandles<AttachmentAPI>(
    eventSingleton,
    {
      addFiles(files) {
        dispatch({type: 'new_files', files})
      },
      remove(attachment) {
        dispatch({type: 'remove', attachment})
      },
      reset() {
        dispatch({type: 'reset'})
      },
    },
    props.subscribed,
  )

  const value = React.useMemo<AttachmentsContext>(
    () => [
      {
        attachments: state.attachments,
        errorMessage: state.errorMessage,
        attachLimit,
      },
      api,
    ],
    [state, api, attachLimit],
  )
  return <attachmentContext.Provider value={value}>{props.children}</attachmentContext.Provider>
}

export function useChatAttachments() {
  const context = React.useContext(attachmentContext)
  invariant(context, 'Expected a AttachmentsProvider')
  return context
}

function filterUnsupportedFiles(files: File[]): File[] {
  return files.filter(file => ACCEPTED_MIME_REGEX.some(type => file.type.match(type)))
}

function areFilesTooLarge(files: File[]): boolean {
  return files.some(file => file.size > MAX_ATTACHMENT_SIZE)
}

try{ AttachmentsProvider.displayName ||= 'AttachmentsProvider' } catch {}