import {Box, PageLayout, Stack} from '@primer/react'
import styles from '../marketplace.module.css'

interface ListingLayoutProps {
  header: React.ReactNode
  body: React.ReactNode
  sidebar: React.ReactNode
}

export const ListingLayout = (props: ListingLayoutProps) => {
  const {header, body, sidebar} = props

  return (
    <div className="d-flex flex-column" data-testid={'marketplace-listing'} data-hpc>
      <PageLayout columnGap="normal" sx={{p: 3}}>
        <PageLayout.Header>{header}</PageLayout.Header>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            flexGrow: [0, 0, 1],
            flexShrink: 0,
            flexDirection: ['column-reverse', 'column-reverse', 'row'],
          }}
        >
          <PageLayout.Content as="div" sx={{pt: 0}}>
            {body}
          </PageLayout.Content>
          <PageLayout.Pane hidden={{narrow: true, regular: false}} className={styles['marketplace-sidebar__pane']}>
            <Stack gap={'spacious'} className={styles['marketplace-sidebar__content-wrapper']}>
              {sidebar}
            </Stack>
          </PageLayout.Pane>
        </Box>
      </PageLayout>
    </div>
  )
}

try{ ListingLayout.displayName ||= 'ListingLayout' } catch {}