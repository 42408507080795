import {Marked} from 'marked'

import type {SafeHTMLString} from '@github-ui/safe-html'

import type {CopilotMarkdownExtension, SanitizerExtension} from './extension'
import {sanitizeHtml} from './sanitize-html'

/**
 * You should probably use MarkdownRenderer rather than calling this directly.
 */
export function transformContentToHTML(body: string, extensions: readonly CopilotMarkdownExtension[]): SafeHTMLString {
  const renderer = new Marked({
    gfm: true,
    breaks: true,
  })

  const sanitizerExtensions: SanitizerExtension[] = []

  let preprocessed = body
  for (const extension of extensions) {
    if (extension.preprocessMarkdown) preprocessed = extension.preprocessMarkdown(preprocessed)

    for (const markedExtension of extension.marked ?? []) renderer.use(markedExtension)

    if (extension.sanitizer) sanitizerExtensions.push(extension.sanitizer)
  }

  const unsafeHTML = renderer.parse(preprocessed) as string

  return sanitizeHtml(unsafeHTML, sanitizerExtensions)
}
