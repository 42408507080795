import {CopyToClipboardButton} from '@github-ui/copy-to-clipboard/Button'
import styles from './CodeBlock.module.css'
import {getLanguageInfo} from '@github-ui/copilot-chat/utils/language-info'
import type {ReactBlockAttributes} from '../../extension'
import hljs from 'highlight.js'
import {useContext, useMemo} from 'react'
import {SafeHTMLDiv} from '@github-ui/safe-html'
import {LanguageDot} from './LanguageDot'
import {ExtensionContext} from '../ExtensionContext'
import {clsx} from 'clsx'

import {char as streamingChar} from '../streaming-cursor'
import streamingCursorStyles from '../streaming-cursor/streaming-cursor.module.css'
import {sendEvent} from '@github-ui/hydro-analytics'

export const languageAttribute = 'data-codeblock-lang'

export const codeBlockAttributes = [languageAttribute]

export function DataAttributesCodeBlock({[languageAttribute]: language = '', children}: ReactBlockAttributes) {
  return <CodeBlock language={language}>{children}</CodeBlock>
}

export interface CodeBlockProps {
  language: string
  children: string
}

export function CodeBlock({language, children}: CodeBlockProps) {
  const {color, name: displayName} = getLanguageInfo(language)

  const isStreaming = children.includes(streamingChar)
  const trimmedCode = children.trim().replaceAll(streamingChar, '')

  const extensionContext = useContext(ExtensionContext)

  const highlighted = useMemo(() => {
    if (!language) return null

    try {
      const highlightedCode = hljs.highlight(trimmedCode, {language}).value
      return isStreaming
        ? `${highlightedCode}<span class="${streamingCursorStyles.streamingCursor}">${streamingChar}</span>`
        : highlightedCode
    } catch {
      // unrecognized language ID; just render plain text
      return null
    }
  }, [trimmedCode, language, isStreaming])

  const code = (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <pre className={styles.code} tabIndex={0}>
      <code>
        {highlighted ? (
          <SafeHTMLDiv unverifiedHTML={highlighted} />
        ) : (
          <>
            {trimmedCode}
            {isStreaming && <span className={streamingCursorStyles.streamingCursor}>{streamingChar}</span>}
          </>
        )}
      </code>
    </pre>
  )

  const copyButton = (
    <CopyToClipboardButton
      textToCopy={trimmedCode}
      ariaLabel="Copy code"
      className={styles.copyButton}
      size={extensionContext.chatMode === 'assistive' ? 'small' : 'medium'}
      onCopy={() => {
        sendEvent('dotcom_chat.activate', {
          target: 'CODE_BLOCK_COPY',
          mode: extensionContext.chatMode,
        })
      }}
    />
  )

  return (
    <figure
      className={clsx(styles.container, {
        [styles.immersive]: extensionContext.chatMode === 'immersive',
        [styles.assistive]: extensionContext.chatMode === 'assistive',
      })}
    >
      <figcaption className={styles.header}>
        <LanguageDot color={color} />
        <span className={styles.languageName}>{displayName || 'Code'}</span>
      </figcaption>
      <div className={styles.copyContainer}>
        <div className={styles.copyContent}>
          {
            // hide button while streaming to avoid flickering tooltip / copying partial code
            !extensionContext.isStreaming && copyButton
          }
        </div>
      </div>
      <div className={styles.codeContainer}>{code}</div>
    </figure>
  )
}

try{ DataAttributesCodeBlock.displayName ||= 'DataAttributesCodeBlock' } catch {}
try{ CodeBlock.displayName ||= 'CodeBlock' } catch {}